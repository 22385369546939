.paper-solicitation-container {
    width: 100%;
    border-radius: 5px;
    background: transparent;
    border: 1px solid #E5E5E5;
    padding: 8px;
}

.paper-solicitation-container.active {
    border-color: #009CF5;
}

.paper-solicitation-status-content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
}

.paper-solicitation-status-content span {
    margin-left: 8px;
    font-size: 14px;
    font-weight: bold;
}

.paper-solicitation-summary {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 4px;
}

.paper-solicitation-summary span {
    font-size: 13px;
}

.paper-solicitation-button-content {
    margin-top: 16px;
}

.paper-solicitation-redicrect-hr {
    height: 1px;
    background-color: #a3a3a3;
    margin: 16px 16px;
}

.paper-solicitation-redicrect-title {
    font-size: 16px;
    font-weight: bold;
}

.paper-solicitation-redicrect-subtitle {
    font-size: 13px;
    font-weight: bold;
}

.paper-solicitation-redicrect-content {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.paper-solicitation-redicrect-providers-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 8px;
    row-gap: 8px;
    margin-top: 8px;
}

.paper-solicitation-redicrect-forms-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.paper-solicitation-redicrect-forms-item {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.paper-solicitation-redicrect-forms-item span {
    font-weight: bold;
}

.paper-solicitation-redicrect-forms-button-group {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 6px;
    margin-top: 6px;
}

.paper-solicitation-redicrect-button-group {
    display: flex;
    width: 100%;
    margin-top: 16px;
}