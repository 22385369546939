.leads-container {
    display: flex;
    flex-direction: row;
    height: calc(100vh - 93px);
}

.leads-contacts-content {
    width: 20%;
    height: calc(100% - 32px);
    padding: 16px;
    overflow-y: auto;
}

.leads-contacts-title {
    font-size: 16px;
    font-weight: bold;
}

.leads-contacts-grid {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 8px;
    margin-top: 16px;
}

.leads-contacts-grid-hr {
    background: #e3e3e3;
    height: 1px;
    margin-bottom: 8px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-self: center;
}

.leads-contacts-grid-hr.today::before {
    content: 'Hoje';
    position: relative;
    margin: 0px auto;
    background: white;
    color: #a3a3a3;
    padding: 0 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 16px;
}

.leads-contacts-grid-hr.other-days::before {
    content: 'Outros Dias';
    position: relative;
    margin: 0px auto;
    background: white;
    color: #a3a3a3;
    padding: 0 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 16px;
}

.leads-chat-content {
    width: 80%;
    height: 100%;
    background-color: #E5E5E5;
    display: flex;
    flex-direction: row;
}

.leads-chat-grid { 
    background-color: white;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.leads-messages-content {
    border: 1px solid #E5E5E5;
    overflow: hidden;
}

.leads-messages-header {
    height: calc(8% - 16px);
    padding: 8px 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.leads-messages-header span {
    font-size: 24px;
    font-weight: bold;
}

.leads-messages-header-hr {
    height: 1px;
    background-color: #E5E5E5;
}

.leads-messages-header-avatar {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.leads-chat-header-content {
    margin-left: 16px;
}

.leads-chat-header-content h1 {
    margin: 0;
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 3px;
}

.leads-chat-header-content span {
    color: #A3A3A3;
    font-size: 1rem;
    font-weight: normal;
}

.leads-messages-body {
    background-color: #f9f9f9;
    height: calc(100% - 72px - 72px - 32px); /* header: 72px | footer: 72px | body: 32px*/
    display: flex;
    flex-flow: column nowrap;
    padding: 16px;
    overflow-y: auto;
}

/* Estilo para o corpo do scroll */
.leads-messages-body::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

/* Estilo para o trilho do scroll */
.leads-messages-body::-webkit-scrollbar-track {
    background: #E5E5E5;
}

/* Estilo para o polegar do scroll */
.leads-messages-body::-webkit-scrollbar-thumb {
    background-color: #009CF5;
}

/* Estilo ao passar o mouse sobre o polegar do scroll */
.leads-messages-body::-webkit-scrollbar-thumb:hover {
    background-color: #009CF5;
}

.leads-messages-body > :first-child {
    margin-top: auto !important;
}

.leads-messages-footer {
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 8px;
}

.leads-messages-footer textarea {
    width: 100%;
    height: 50px;
    margin: 0px 8px;
    resize: none;
    padding: 2px 8px;
    border-color: #E5E5E5;
    background-color: #fdfdfd;
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.leads-solicitation-forms-item {
    margin-bottom: 16px;
}

.leads-solicitation-forms-item span {
    font-size: 16px;
    font-weight: bold;
}

.leads-solicitation-button-grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    column-gap: 8px;
    margin-top: 8px;
}

.leads-solicitation-forms-title-group {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.leads-solicitation-forms-title-group button {
    background: transparent;
    border: none;
    color: #009CF5;
    margin-left: 16px;
    cursor: pointer;
}

.leads-solicitation-forms-button-group {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 16px;
    margin: 8px 0;
}

.leads-solicitation-forms-vehicle {
    color: #009CF5;
    font-size: 12px !important;
}

.leads-contacts-done {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.leads-contacts-done img {
    width: 80%;
}

.leads-contacts-done span {
    font-weight: bold;
    margin-top: 8px;
}

.new-solicitation-type-services-button-group {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    column-gap: 6px;
}

.new-solicitation-type-services-button {
    background: transparent;
    border: 1px solid #009CF5;
    border-radius: 5px;
    padding: 8px 16px;
    font-size: 13px;
    cursor: pointer;
}

.new-solicitation-type-services-button.active {
    background: #009CF5;
    color: white;
    font-weight: bold;
}

@media (max-width: 1280px) {
    .paper-leads-info span {
        max-width: 130px
    }

    .leads-chat-header-content h1 {
        font-size: 1rem;
        margin-bottom: 0px;
    }

    .leads-chat-header-content span {
        font-size: .8rem;
    }

    .leads-solicitation-button-grid {
        grid-template-columns: repeat(3, 1fr);
        row-gap: 8px;
    }
}

@media (max-width: 1023px) {
    .leads-container {
        flex-direction: column;
        height: 100%;
    }

    .leads-contacts-content {
        width: calc(100% - 32px);
        padding: 16px;
    }

    .leads-chat-content {
        width: 100%;
    }

    .leads-chat-grid {
        display: flex;
        flex-direction: column;
    }

    .leads-messages-content {
        padding: 0px 8px;
    }

    .leads-messages-header {
        height: auto;
        padding: 8px 0px;
    }

    .leads-chat-header-content h1 {
        font-size: 1rem;
        margin-bottom: 0px;
    }

    .leads-chat-header-content span {
        font-size: .8rem;
    }

    .leads-messages-body {
        height: calc(100vh - 58px - 72px - 93px - 32px);
    }

    .leads-solicitation-button-grid {
        grid-template-columns: repeat(3, 1fr);
        row-gap: 8px;
    }

    .modal-finish-contact-container {
        width: 80%;
    }
}