.paper-solicitation-summury-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid #E5E5E5;
    border-radius: 5px;
    padding: 8px 16px;
    background: transparent;
    width: 100%;
    cursor: pointer;
}

.paper-solicitation-summury-container.active {
    border-color: #009CF5;
}

.paper-solicitation-summury-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: initial;
    margin-left: 16px;
}

.paper-solicitation-summury-footer {
    margin-top: 16px;
}