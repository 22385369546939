.leads-solicitation-active-container {
    margin-top: 24px;
}

.leads-solicitation-active-hr {
    height: 1px;
    width: 100%;
    background-color: #A3A3A3;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
}

.leads-solicitation-active-hr::before {
    content: 'Em Andamento';
    color: #A3A3A3;
    background-color: white;
    padding: 0 16px;
}

.leads-solicitation-active-content,
.leads-solicitation-active-links-content {
    display: flex;
    flex-direction: column;
}

.leads-solicitation-active-content span,
.leads-solicitation-active-describe-content span {
    font-size: 13px;
}

.leads-solicitation-active-describe-item {
    display: flex;
    flex-direction: column;
}

.table-solicitation-active-title {
    font-size: 16px !important;
    font-weight: bold;
    margin-bottom: 8px;
}

.leads-solicitation-active-content button, 
.table-solicitation-active-title button {
    background: transparent;
    color: #009CF5;
    border: none;
    cursor: pointer;
}

.leads-solicitation-active-describe-content {
    margin-top: 16px;
}

.table-service-proposal-container {
    width: 100%;
    overflow-x: auto;
}

/* Estilo para o corpo do scroll */
.table-service-proposal-container::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

/* Estilo para o trilho do scroll */
.table-service-proposal-container::-webkit-scrollbar-track {
    background: #E5E5E5;
}

/* Estilo para o polegar do scroll */
.table-service-proposal-container::-webkit-scrollbar-thumb {
    background-color: #009CF5;
}

/* Estilo ao passar o mouse sobre o polegar do scroll */
.table-service-proposal-container::-webkit-scrollbar-thumb:hover {
    background-color: #009CF5;
}

.table-solicitation-active-footer {
    display: flex;
    flex-direction: row;
    margin-top: 16px;
    justify-content: flex-end;
}

@media (max-width: 1280px) {
    .table-solicitation-active-title {
        font-size: 13px !important;
    }

    .leads-solicitation-active-content span, 
    .leads-solicitation-active-describe-content span {
        font-size: 11px;
    }
}