.mapping-container {
    display: flex;
    height: calc(100vh - 93px);
}

.mapping-sidebar-container {
    width: calc(25% - 32px);
    padding: 16px;
}

.mapping-sidebar-title {
    font-weight: bold;
}

.mapping-sidebar-solicitations-grid {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 16px;
    margin-top: 8px;
}

.mapping-content {
    width: 75%;
    height: 100%;
}

.mapping-sidebar-done {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mapping-sidebar-done img {
    width: 80%;
}

.mapping-sidebar-done span {
    font-size: 13px;
    margin-top: 16px;
}

@media only screen and (max-width: 768px) {
    .mapping-sidebar-container {
        width: 100%;
        height: calc(100% - 32px);
    }
}