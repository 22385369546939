.partners-dashboard-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    background-color: white;
    height: 100%;
    padding: 16px;
    border-radius: 10px;
}

.partners-dashboard-forms-item {
    margin-bottom: 16px;
}

.partners-dashboard-forms-item span,
.partners-dashboard-users-item span {
    font-weight: bold;
}

.partners-dashboard-forms-button {
    background-color: transparent;
    border: 1px solid gray;
    border-radius: 5px;
    padding: 8px;
    cursor: pointer;
}

.partners-dashboard-forms-button.active {
    border-color: #009CF5;
    background-color: #009CF5;
    color: white;
    font-weight: bold;
}

.partners-dashboard-data-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.partners-dashboard-data-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0px;
}

.partners-dashboard-data-item h3 {
    margin: 0;
    margin-bottom: 16px;
}

.partners-dashboard-forms-link {
    color: #009CF5;
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 14px;
    margin-left: 16px;
}

.partners-dashboard-users-content {
    padding-left: 32px;
}

.partners-dashboard-users-grid {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 8px;
    margin-top: 16px;
}

.partners-dashboard-users-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 16px;
}

.partners-dashboard-users-header button {
    background-color: transparent;
    color: #009CF5;
    font-size: 15px;
    border: none;
    margin-left: 16px;
    cursor: pointer;
}