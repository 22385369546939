:root {
    --rsbs-backdrop-bg: rgba(0, 0, 0, 0);
}

.bottom-sheet-container div[data-rsbs-backdrop] {
    top: auto !important;
    
}

.bottom-sheet-mapping-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 16px;
}

.bottom-sheet-mapping-title {
    display: flex;
    font-weight: bold;
    justify-content: center;
    margin-bottom: 8px;
}

.bottom-sheet-mapping-subtitle {
    display: flex;
    justify-content: center;
}