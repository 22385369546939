.modal-order-service-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 16px;
    border-radius: 10px;
    width: 85%;
}

.modal-order-service-title-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal-order-service-title-content h2 {
    margin: 0;
    margin-bottom: 6px;
}

.modal-order-service-content {
    padding: 0px 16px;
}

.modal-order-service-title {
    font-weight: bold;
    margin-bottom: 6px;
}

.modal-order-service-button {
    background-color: transparent;
    border-radius: 10px;
    border: 1px solid #009CF5;
    padding: 8px;
    cursor: pointer;
}

.modal-order-service-button.active {
    background-color: #009CF5;
    color: white;
}