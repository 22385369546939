.paper-partner-user-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px 24px;
    border: 1px solid #E5E5E5;
    border-radius: 10px;
}

.paper-partner-user-container.root {
    background-color: #f1f1f1;
}

.paper-partner-user-data-content {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.paper-partner-user-data {
    display: flex;
    flex-direction: column;
    margin-left: 24px;
}

.paper-partner-user-data span {
    font-weight: inherit;
    font-size: 13px;
    color: #a3a3a3;
}

.paper-partner-user-data label {
    font-weight: bold;
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.paper-partner-user-type {
    color: #009CF5;
}

.paper-partner-user-type-content {
    display: flex;
    flex-direction: row;
    align-items: center;
}