.company-forms-container {
    padding: 16px 32px;
    border-radius: 10px;
}

.company-forms-container.shadow {
    background-color: white;
    box-shadow: 1px 1px 4px 0px #b9b9b9
}

.company-forms-title-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 16px;
}

.company-forms-title-content span {
    font-size: 18px;
    font-weight: bold;
}

.company-forms-title-content button {
    margin-left: 8px;
    background: transparent;
    color: #009CF5;
    border: none;
    font-size: 13px;
    cursor: pointer;
}

.company-forms-input-group-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 8px;
    margin-bottom: 8px;
}

.company-forms-input-group-3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 8px;
    margin-bottom: 8px;
}

.company-forms-input-address {
    display: grid;
    grid-template-columns: 2fr .25fr .71fr;
    column-gap: 8px;
    margin-bottom: 8px;
}

.company-forms-input-bank-account {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 0.3fr;
    column-gap: 8px;
    margin-bottom: 8px;
}

.company-forms-input-subtitle {
    font-size: 16px;
    font-weight: bold;
}

.company-forms-button-content {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
}