.paper-message {
    width: fit-content;
    padding: 8px 16px;
    border: 1px solid #A3A3A3;
    border-radius: 5px;
    background-color: white;
    margin: 6px 0px;
    min-width: 30%;
    max-width: 50%;
}

.paper-message p {
    font-size: 13px;
    color: #2D2D2D;
    word-break: break-word;
}

.paper-message.delivery {
    align-self: flex-end;
}

.paper-message.receive {
    align-self: flex-start;
}

.paper-message-footer {
    color: #a2a2a2;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 2px;
}

.paper-message-footer span {
    font-size: 10px;
}

.paper-message-content img,
.paper-message-content video {
    object-fit: cover;
    width: 100%;
    height: 200px;
    cursor: pointer;
}

.paper-message-content audio {
    width: 100%;
    object-fit: cover;
}

.paper-message-content .location {
    height: 200px;
    border-radius: 10px;
    padding-top: 8px;
}

@media (max-width: 1280px) {
    .paper-message.audio {
        padding: 8px 16px;
        width: calc(260px - 32px);
        max-width: none;
    }
}