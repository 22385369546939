.modal-finish-contact-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 16px;
    border-radius: 10px;
}

.modal-finish-contact-header {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal-finish-contact-header h2 {
    margin: 0;
    margin-bottom: 6px;
}

.modal-finish-contact-body {
    display: flex;
    flex-direction: column;
    margin: 8px 0;
    width: 100%;
}

.modal-finish-contact-body span {
    font-weight: bold;
    margin-bottom: 8px;
}

.modal-finish-contact-footer {
    margin-top: 16px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 16px;
}