.partners-container {
    display: flex;
    flex-direction: row;
    height: calc(100vh - 93px);
}

.partners-list-container {
    display: flex;
    flex-direction: column;
    width: 20%;
}

.partners-list-content {
    padding: 8px;
}

.partners-list-content span {
    font-weight: bold;
}

.partners-list-grid {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 8px;
    margin-top: 8px;
    margin-bottom: 32px;
}

.partners-data-container {     
    display: flex;
    flex-direction: column;
    width: 80%;
    background-color: #E5E5E5;
    padding: 32px;
}