.paper-partner-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 8px;
    border-radius: 10px;
    border: 1px solid #a3a3a3;
    cursor: pointer;
    background-color: transparent;
}

.paper-partner-container:hover {
    border-color: #009CF5;
}

.paper-partner-container.active {
    background-color: #009CF5;
    color: white;
}

.paper-partner-container.disabled {
    opacity: .5;
    cursor: initial;
}

.paper-partner-container.disabled:hover {
    border-color: #a3a3a3;
}

.paper-partner-content {
    margin-left: 16px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.paper-partner-content span {
    font-weight: bold;
}