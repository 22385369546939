.modal-price-calculator-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 16px;
    border-radius: 10px;
    width: 85%;
}

.modal-price-calculator-title-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal-price-calculator-title-content h2 {
    margin: 0;
    margin-bottom: 6px;
}

.modal-price-calculator-content {
    padding: 0px 16px;
}

.modal-price-calculator-title {
    font-weight: bold;
    margin-bottom: 6px;
}