.providers-container {
    display: grid;
    grid-template-columns: .4fr 1fr;
}

/*list companies*/
.providers-company-list-content {
    padding: 16px 16px;
    height: calc(100vh - 32px - 93.4px);
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    row-gap: 8px;
}

.providers-company-list-item {
    padding-bottom: 8px;
    height: calc(100% - 8px);
    overflow-y: hidden;
}

.providers-company-list-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 8px;
}

.providers-company-list-title label {
    margin-left: 8px;
}

.providers-company-list {
    height: calc(100% - 40px);
    overflow-y: auto;
    padding: 8px;
}

.providers-company-paper-group {
    grid-template-columns: 1fr;
    display: grid;
    row-gap: 8px;
}

/*forms company*/
.providers-company-edition-content {
    padding: 24px;
    background-color: #f1f1f1;
    min-height: calc(100vh - 93.4px - 48px);
}

.providers-company-selected-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}