.table-solicitation-collapse-container {
    padding: 16px;
}

.table-solicitation-info-container {
    margin-bottom: 16px;
    display: grid;
    grid-template-columns: 2fr .5fr;
    column-gap: 8px;
}

.table-solicitation-info-title {
    font-size: 16px;
    font-weight: bold;
}

.table-solicitation-info-content,
.table-solicitation-proposal-content {
    display: flex;
    flex-direction: column;
}

.table-solicitation-info-content span {
    font-size: 13px;
}

.table-solicitation-footer {
    display: flex;
    justify-content: flex-end;
    padding: 8px 16px;
}

.table-solicitation-footer button {
    background: #009CF5;
    color: white;
    font-weight: bold;
    font-size: 14px;
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
}

.table-solicitation-hr {
    height: 1px;
    width: 100%;
    background: #E5E5E5;
}

.table-solicitation-info-icon-button {
    width: 35px;
    height: 35px;
    border: none;
    border-radius: 50%;
    margin-left: 16px;
    background: transparent;
    background-image: url(../../../assets/icon/whatsapp.svg);
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}

.table-solicitation-info-content button {
    margin-top: 6px;
    background: white;
    border: 1px solid #009CF5;
    border-radius: 5px;
    padding: 6px;
    cursor: pointer;
}

.table-button-link {
    border: none !important;
    background-color: transparent;
    color: #009CF5;
    padding: 0 !important;
    margin: 0 !important;
}
