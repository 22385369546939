.paper-leads-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    padding: 8px;
    border-radius: 10px;
    border: 1px solid #E5E5E5;
    cursor: pointer;
    background-color: transparent;
}

.paper-leads-container:hover {
    border-color: #009CF5;
}

.paper-leads-container.active {
    background-color: #009CF5;
    color: white;
}

.paper-leads-avatar-content {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.paper-leads-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 16px;
    overflow: hidden;
}

.paper-leads-info strong {
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
}

.paper-leads-info span {
    font-size: 13px;
    color: #A3A3A3;
    margin-top: 3px;
    max-width: 230px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}