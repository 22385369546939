.paper-company-container {
    border-radius: 5px;
    border: none;
    padding: 8px 16px;
    cursor: pointer;
}

.paper-company-container.shadow {
    background-color: white;
    box-shadow: 1px 1px 4px 0px #b9b9b9
}

.paper-company-container.inactive {
    background-color: #E5E5E5;
    opacity: .5;
}

.paper-company-container.selected {
    box-shadow: 1px 1px 4px 0px #009CF5
}

.paper-company-title-content {
    display: flex;
    justify-content: space-between;
}

.paper-company-name {
    font-size: 16px;
    font-weight: bold;
}

.paper-company-providers-count {
    font-size: 13px;
    font-weight: bold;
}

.paper-company-providers-count label {
    margin-left: 3px;
}