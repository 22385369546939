.layout-header-container {
    
}

.layout-header-avatar-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #009CF5;
    padding: 8px 16px;
}

.layout-header-avatar-content {
    display: flex;
    align-items: center;
}

.layout-header-user-content  {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
    max-width: 200px;
}

.layout-header-user-content label {
    color: white;
    font-weight: bold;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.layout-header-user-content span {
    color: #2D2D2D;
    font-size: 13px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.layout-button {
    background: transparent;
    color: white;
    border: none;
    font-size: 16px;
    cursor: pointer;
    white-space: nowrap;
}

.layout-button.active::after {
    content: '';
    display: block;
    height: 1px;
    width: 100%;
    background: white;
}

.layout-header-menu-container {
    background-color: #2D2D2D;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 16px);
    overflow-x: auto;
}

@media screen and (max-width: 480px) {
    .layout-header-menu-container {
        align-items: normal;
        justify-content: initial;
    }
  }